import env from './env';

export function getSignupUrl(language = 'en') {
  if (env.WHITELABEL_BRAND === 'wealthbar') {
    return (language && language.slice(0, 2) === 'fr') ? env.COMMON_DASHBOARD_SIGNUP_FR : env.COMMON_DASHBOARD_SIGNUP_EN;
  } else {
    return '/';
  }
}

export default {
  getSignupUrl,
};
