/* eslint-disable */
import Chartist from 'chartist';
import storage from '../utils/storage';
import { getSignupUrl } from '../utils/external_links';

const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0;

const plan = {
  age: 35,
  savings: 60000,
  monthlyContribution: 500,
  retirementAge: 65,
  interestRate: 0.070,
  inflationRate: 0.020,
};

const wealthBarMER = 0.0025;
const mutualFundMER = 0.0193;

const schedule = [
  { min: 0, max: 5000, fee: 0 },
  { min: 5000, max: 150000, fee: 0.006 },
  { min: 150000, max: 500000, fee: 0.004 },
  { min: 500000, max: Number.MAX_VALUE, fee: 0.0035 },
];

const chartOptions = {
  low: 0,
  showArea: true,
  showLine: true,
  showPoint: false,
  fullWidth: true,
  chartPadding: {
    top: 0,
    right: 0,
    bottom: 24,
    left: 0,
  },
  axisX: {
    offset: 0,
    showGrid: false,
    showLabel: false,
  },
  axisY: {
    offset: 0,
    showGrid: false,
    showLabel: false,
  },
  series: {
    'wealthbar': {
      showLine: false,
    },
    'mutualfund': {
      showArea: false,
    },
  }
};

const startSliderValues = [1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000, 325000, 350000, 375000, 400000, 425000, 450000, 475000, 500000, 525000, 550000, 575000, 600000, 625000, 650000, 675000, 700000, 725000, 750000, 775000, 800000, 825000, 850000, 875000, 900000, 925000, 950000, 975000, 1000000];
const ongoingSliderValues = [50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425, 450, 475, 500, 525, 550, 575, 600, 625, 650, 675, 700, 725, 750, 775, 800, 825, 850, 875, 900, 925, 950, 975, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4250, 4500, 4750, 5000, 5250, 5500, 5750, 6000, 6250, 6500, 6750, 7000, 7250, 7500, 7750, 8000, 8250, 8500, 8750, 9000, 9250, 9500, 9750, 10000];

const currencyStyle = { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 };

let setLanguage = 'en-CA';

let scrollArticle;
let scrollValue;
let optionsTop;
let performanceTop;
let links;

let whiteBg;
let invertBg;
let optionsScreen;
let performanceScreen;

let wealthbarChart;
let prevArea;
let prevLine;

// ------ Scrolling effect functions ------ //

// return element positioning
function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

// Change Background and phone screen based on scroll position
function backgroundSet(screenScrollPosition) {
  if (screenScrollPosition < optionsTop) {
    whiteBg.style.opacity = 0;
    invertBg.style.opacity = 0;
    scrollArticle.style.color = '#303030';
    for (let i = 0; i < links.length; i++) { links[i].removeAttribute('style'); }
  } else if (screenScrollPosition >= optionsTop && screenScrollPosition < performanceTop) {
    whiteBg.style.opacity = 1;
    invertBg.style.opacity = 0;
    optionsScreen.style.opacity = 1;
    performanceScreen.style.opacity = 0;
    scrollArticle.style.color = '#303030';
    for (let i = 0; i < links.length; i++) { links[i].removeAttribute('style'); }
  } else if (screenScrollPosition >= performanceTop) {
    whiteBg.style.opacity = 1;
    invertBg.style.opacity = 0;
    optionsScreen.style.opacity = 0;
    performanceScreen.style.opacity = 1;
    scrollArticle.style.color = '#303030';
    for (let i = 0; i < links.length; i++) { links[i].removeAttribute('style'); }
  }
}

function setPage() {
  const screenScrollPosition = (window.pageYOffset || document.documentElement.scrollTop) + (window.innerHeight / 2);
  // remove background fallbacks
  const banner = document.getElementById('banner') || document.getElementById('header');
  banner.removeAttribute('class');
  document.getElementById('intro').removeAttribute('class');
  document.getElementById('main').removeAttribute('class');

  // setup scolling elements
  scrollArticle = document.getElementById('scroll-article');
  optionsTop = offset(document.getElementById('options')).top;
  performanceTop = offset(document.getElementById('performance')).top;
  links = scrollArticle.getElementsByTagName('a');
  whiteBg = document.getElementById('bg-white');
  invertBg = document.getElementById('bg-invert');

  // set phone screen elements
  optionsScreen = document.getElementById('scroll-phone-options');
  performanceScreen = document.getElementById('scroll-phone-performance');

  backgroundSet(screenScrollPosition);

  return true;
}

// Scroll fallback for no position:sticky support //
function scrollElement(element, container) {
  const elementHeight = element.getBoundingClientRect().height;
  const scrollHeight = (window.innerHeight / 2) - (elementHeight / 2);
  const scrollContainer = container.getBoundingClientRect();

  // Check scrolling position
  if (scrollHeight < scrollContainer.top) {
    element.style.position = 'relative';
    element.style.top = '';
    element.style.left = '';
    element.style.width = scrollContainer.width + 'px';
    element.setAttribute('data-scroll', 'top');
  } else if (scrollHeight > scrollContainer.bottom - elementHeight) {
    element.style.position = 'relative';
    element.style.top = (scrollContainer.height - elementHeight) + 'px';
    element.style.left = '';
    element.style.width = scrollContainer.width + 'px';
    element.setAttribute('data-scroll', 'bottom');
  } else {
    element.style.position = 'fixed';
    element.style.top = scrollHeight + 'px';
    element.style.left = scrollContainer.left + 'px';
    element.style.width = scrollContainer.width + 'px';
    element.setAttribute('data-scroll', 'scroll');
  }
}

// checks browser support of position:sticky
function canUseSticky() {
  return window.CSS && window.CSS.supports && (window.CSS.supports('position', 'sticky') || window.CSS.supports('position', '-webkit-sticky'));
}

function checkScroll() {
  const phone = document.getElementById('scroll-phone');
  if (window.matchMedia('(min-width: 48rem)').matches) {
    if (!canUseSticky()) {
      scrollElement(phone, document.querySelector('aside'));
    }
    return true;
  }
  phone.removeAttribute('style');
  return false;
}

// ------ Chart calculation functions ------ //

function calculateFeePercentage(assets) {
  let bracket;
  for (let bracketIndex in schedule) {
    bracket = schedule[bracketIndex];
    if (assets <= bracket.max) {
      return bracket.fee;
    }
  }
  return bracket.fee;
}

// returns data to plot chart
function calculatePlanData(start, ongoing) {
  const realRate = ((1 + plan.interestRate) / (1 + plan.inflationRate)) - 1;
  const realMonthlyRate = realRate / 12;
  const savingsPeriods = (plan.retirementAge - plan.age) * 12;
  const depositAmt = ongoing;
  const ageInMonths = plan.age * 12;
  const data = [];
  const wealthBarData = [];
  const mutualFundData = [];

  for (let i = 0; i <= savingsPeriods; i++) {
    if (i === 0) {
      data[i] = {
        wealthBarFund: start,
        mutualFund: start,
        fees: 0,
        mutualFundFees: 0,
        month: ageInMonths,
        payments: start,
      };
      wealthBarData[i] = start;
      mutualFundData[i] = start;
    } else {
      const fees = (data[i - 1].wealthBarFund / 12) * (calculateFeePercentage(data[i - 1].savings) + wealthBarMER);
      const mutualFundFees = (data[i - 1].mutualFund / 12) * mutualFundMER;
      data[i] = {
        wealthBarFund: Math.floor(((data[i - 1].wealthBarFund + depositAmt) - fees) * (1 + realMonthlyRate)),
        mutualFund: Math.floor(((data[i - 1].mutualFund + depositAmt) - mutualFundFees) * (1 + realMonthlyRate)),
        fees: fees,
        mutualFundFees: mutualFundFees,
        month: i + ageInMonths,
        payments: depositAmt,
      };
      wealthBarData[i] = data[i].wealthBarFund;
      mutualFundData[i] = data[i].mutualFund;
    }
  }
  return {
    wealthBarData: wealthBarData,
    wealthBarValue: data[data.length - 1].wealthBarFund,
    mutualFundData: mutualFundData,
    mutualFundValue: data[data.length - 1].mutualFund,
  };
}

// return slider value based on position
function sliderValue(elementId, values) {
  const sliderElement = document.getElementById(elementId);
  const valueElement = document.getElementById(elementId + '-value');
  const setValue = values[sliderElement.value];

  sliderElement.setAttribute('max', values.length - 1);
  valueElement.textContent = setValue.toLocaleString(setLanguage, currencyStyle);

  return setValue;
}

// update values in calculator
function updateCalculatorValues() {
  const savingsElement = document.getElementById('savings-value');
  const wbValueElement = document.getElementById('chart-wealthbar-value');
  const mfValueElement = document.getElementById('chart-mutualfund-value');

  const startValue = sliderValue('start-range', startSliderValues);
  const ongoingValue = sliderValue('ongoing-range', ongoingSliderValues);
  const planData = calculatePlanData(startValue, ongoingValue);

  savingsElement.textContent = (planData.wealthBarValue - planData.mutualFundValue).toLocaleString(setLanguage, currencyStyle);
  wbValueElement.textContent = planData.wealthBarValue.toLocaleString(setLanguage, currencyStyle);
  mfValueElement.textContent = planData.mutualFundValue.toLocaleString(setLanguage, currencyStyle);

  return planData;
}

function sliderListener(rangeName) {
  const eventTrigger = isIE ? 'change' : 'input';
  document.getElementById(rangeName).addEventListener(eventTrigger, function () {
    const chartValues = updateCalculatorValues();
    if (isIE) {
      const chartData = {
        series: [
          {
            name: 'wealthbar',
            data: chartValues.wealthBarData,
          },
          {
            name: 'mutualfund',
            data: chartValues.mutualFundData,
          }]
      };
      wealthbarChart.update(chartData);
    }
    // const chartData = { series: [chartValues.wealthBarData, chartValues.mutualFundData] };
    // wealthbarChart.update(chartData);
  });
  if (!isIE) {
    document.getElementById(rangeName).addEventListener('change', function () {
      const chartValues = updateCalculatorValues();
      const chartData = {
        series: [
          {
            name: 'wealthbar',
            data: chartValues.wealthBarData,
          },
          {
            name: 'mutualfund',
            data: chartValues.mutualFundData,
          }]
      };
      wealthbarChart.update(chartData);
    });
  }
}


// Chartist Animation
Chartist.plugins = Chartist.plugins || {};
Chartist.plugins.ctAnimate = function () {
  return function ctAnimate(chart) {
    chart.on('draw', function (data) {
      if (data.type === 'area') {
        data.element.animate({
          d: {
            begin: 0,
            dur: 1000,
            from: prevArea ? prevArea.stringify() : data.path.clone().scale(1, 0).translate(1, data.chartRect.height()).stringify(),
            to: data.path.stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
        prevArea = data.path;
      }
      if (data.type === 'line') {
        data.element.animate({
          d: {
            begin: 0,
            dur: 1000,
            from: prevLine ? prevLine.stringify() : data.path.clone().scale(1, 0).translate(1, data.chartRect.height()).stringify(),
            to: data.path.stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
        prevLine = data.path;
      }
    });
  };
};

// ------ Toggle Section functions ------ //

function expandSection(element) {
  const sectionHeight = element.scrollHeight;
  element.style.height = sectionHeight + 'px';
  element.addEventListener('transitionend', function endTransition() {
    element.removeEventListener('transitionend', endTransition);
    element.style.height = null;
  });
  element.setAttribute('data-collapsed', 'false');
}

function collapseSection(element) {
  const sectionHeight = element.scrollHeight;
  const elementTransition = element.style.transition;
  element.style.transition = '';
  requestAnimationFrame(function () {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;
    requestAnimationFrame(function () {
      element.style.height = 0 + 'px';
    });
  });
  element.setAttribute('data-collapsed', 'true');
}

function setToggle(element) {
  const toggle = document.getElementById(element);
  const section = document.getElementById(toggle.getAttribute('data-toggle'));
  section.style.height = 0 + 'px';
  section.setAttribute('data-collapsed', 'true');
  toggle.addEventListener('click', function () {
    if (section.getAttribute('data-collapsed') === 'true') {
      expandSection(section);
      toggle.setAttribute('data-collapsed', 'false');
    } else {
      collapseSection(section);
      toggle.setAttribute('data-collapsed', 'true');
    }
  });
}

// set toggle specifically for Nav Menu
function setNavigationToggle() {
  const nav = document.getElementById('banner') || document.getElementById('header');
  const toggle = document.getElementById('nav-dropdown-toggle');
  const steamedHam = document.getElementById('hamburger');
  const close = document.getElementById('close');
  toggle.addEventListener('click', function () {
    const isCollapsed = nav.getAttribute('data-collapsed') === 'true';
    if (isCollapsed) {
      nav.setAttribute('data-collapsed', 'false');
      nav.style.position = 'fixed';
      steamedHam.style.opacity = 0;
      close.style.opacity = 1;
      document.body.style.height = '0px';
      document.body.style.overflow = 'hidden';
    } else {
      nav.setAttribute('data-collapsed', 'true');
      nav.style.position = 'relative';
      steamedHam.style.opacity = 1;
      close.style.opacity = 0;
      document.body.removeAttribute('style');
    }
  });
}

// ------ Page Link functions ------ //

function setSignupLink() {
  const signupLinkEls = document.getElementsByClassName('signup-link');
  for (const el of signupLinkEls) {
    const signupLink = getSignupUrl(setLanguage);
    el.setAttribute('href', signupLink);
  }
}

// ------ Language Functions ------ //

function setPageLanguage(language) {
  if (language === 'fr-CA') {
    setLanguage = 'fr-CA';
    window.Transifex.live.translateTo('fr_CA', true);
    document.getElementById('header-image').setAttribute('src', ' /home/header-fr.png');
    document.getElementById('header-mobile').setAttribute('srcset', ' /home/header-fr.png 2x');
    document.getElementById('header-large').setAttribute('srcset', ' /home/header-fr-2x.png 2x');
    document.getElementById('lang-fr').setAttribute('data-active', true);
    document.getElementById('lang-en').removeAttribute('data-active');
    document.getElementById('screen-portfolio').setAttribute('xlink:href', 'home/iphonex-portfolio-fr-2x.png');
    document.getElementById('screen-dashboard').setAttribute('xlink:href', 'home/iphonex-dashboard-fr-2x.png');
    document.getElementById('translate-investment').setAttribute('src', '/home/robo-invest-fr-2x.png');
    storage.setItem('language', 'french');
  } else {
    setLanguage = 'en-CA';
    window.Transifex.live.translateTo('en', true);
    document.getElementById('header-image').setAttribute('src', ' /home/header-en.png');
    document.getElementById('header-mobile').setAttribute('srcset', ' /home/header-en.png 2x');
    document.getElementById('header-large').setAttribute('srcset', ' /home/header-en-2x.png 2x');
    document.getElementById('lang-en').setAttribute('data-active', true);
    document.getElementById('lang-fr').removeAttribute('data-active');
    document.getElementById('screen-portfolio').setAttribute('xlink:href', 'home/iphonex-portfolio-en-2x.png');
    document.getElementById('screen-dashboard').setAttribute('xlink:href', 'home/iphonex-dashboard-en-2x.png');
    document.getElementById('translate-investment').setAttribute('src', '/home/robo-invest-en-2x.png');
    storage.setItem('language', 'english');
  }
  sliderValue('start-range', startSliderValues);
  sliderValue('ongoing-range', ongoingSliderValues);
}

function checkLanguage() {
  if (storage.getItem('language')) {
    if (storage.getItem('language') === 'french') {
      setPageLanguage('fr-CA');
    } else {
      setPageLanguage('en-CA');
    }
  } else if (navigator.language === 'fr-CA' || navigator.language === 'fr') {
    setPageLanguage('fr-CA');
  } else {
    setPageLanguage('en-CA');
  }
}

function setLanguageToggle(id) {
  document.getElementById(id).addEventListener('click', function () {
    if (setLanguage === 'fr-CA') {
      setPageLanguage('en-CA');
    } else {
      setPageLanguage('fr-CA');
    }
    updateCalculatorValues();
    setSignupLink();
  });
}

// ------ JS Load setup ------ //

window.onload = function () {
  window.Transifex.live.onReady(function () {
    checkLanguage();
    setLanguageToggle('language-toggle');
  });

  setPage();
  checkScroll();
  setNavigationToggle();
  setToggle('calc-toggle');
  setSignupLink();

  const chartValues = updateCalculatorValues();
  const chartData = {
    series: [{
      name: 'wealthbar',
      data: chartValues.wealthBarData,
    },
    {
      name: 'mutualfund',
      data: chartValues.mutualFundData,
    }]
  };
  chartOptions.plugins = [Chartist.plugins.ctAnimate()];
  wealthbarChart = new Chartist.Line('#wb-chart', chartData, chartOptions);

  sliderListener('start-range');
  sliderListener('ongoing-range');
};

// ------ JS resize setup ------ //

window.onresize = function () {
  if (!canUseSticky()) {
    // adjust phone position on resize
    const phone = document.getElementById('scroll-phone');
    const scrollContainer = document.querySelector('aside').getBoundingClientRect();
    phone.style.width = scrollContainer.width + 'px';
    const scroll = phone.getAttribute('data-scroll');
    if (scroll === 'scroll') {
      phone.style.left = scrollContainer.left + 'px';
    } else if (scroll === 'bottom') {
      phone.style.top = (scrollContainer.height - phone.getBoundingClientRect().height) + 'px';
    }
  }
  if (window.matchMedia('(min-width: 48rem)').matches) {
    //unset navToggle styles
    document.body.removeAttribute('style');
  }
};

// ------ JS scrolling setup ------ //

window.addEventListener('scroll', function () {
  const scrollPage = window.pageYOffset || document.documentElement.scrollTop;
  let screenScrollPosition;

  // Adjust fade position when scrolling up or down
  if (scrollValue > scrollPage) {
    screenScrollPosition = scrollPage + (window.innerHeight / 4);
  } else {
    screenScrollPosition = scrollPage + (window.innerHeight / 2);
  }
  scrollValue = scrollPage;

  backgroundSet(screenScrollPosition);
  checkScroll();
});
